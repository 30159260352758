import { BLACK_LOGO } from "constants/index";
import LoadingIndicator from "./Shared/LoadingIndicator";

const LoadingScreen: React.FC<{ isLoading?: boolean }> = ({
  isLoading = true,
}) => {
  return (
    <div className="flex flex-col text-center items-center justify-center px-4 py-6 bg-primary-800 h-screen">
      <img className="h-14 mb-14" src={BLACK_LOGO} alt="Creative Guru Social" />

      <h1 className="mb-4 text-3xl font-extrabold leading-none tracking-tight text-white xl:text-5xl whitespace-pre-line">
        AI Driven Social Content Creation.
      </h1>

      {isLoading && (
        <div className="mt-20 text-white">
          <LoadingIndicator />
          Loading...
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
