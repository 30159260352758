import { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Flowbite } from "flowbite-react";

import ErrorBoundary from "ErrorBoundary";
import MobilePrompt from "web/components/MobilePrompt";
import { PWAInstallPromptProvider } from "context/pwa/pwa";
import AppProvider from "context/context";
import LoadingScreen from "web/components/LoadingScreen";

const WebLayoutLazy = lazy(() => import("containers/WebLayout"));
function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AppProvider>
          <PWAInstallPromptProvider>
            <Suspense fallback={<LoadingScreen isLoading={false} />}>
              <Flowbite>
                <MobilePrompt />
                <WebLayoutLazy />
              </Flowbite>
            </Suspense>
          </PWAInstallPromptProvider>
        </AppProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
