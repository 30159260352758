import { UserType } from "types/campaign";


type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  }
};

export enum AuthActionTypes {
  SET_USER = "SET_USER",
  SET_USER_LOADING = "SET_USER_LOADING",
  SET_CLICKFARM_USER = "SET_CLICKFARM_USER",
}



export type AuthTypes = {
  user: null | UserType
  userLoading: boolean;
  isClickFarmUser: boolean

}

type AuthPayload = {
  [AuthActionTypes.SET_USER]: UserType
  [AuthActionTypes.SET_USER_LOADING]: boolean
  [AuthActionTypes.SET_CLICKFARM_USER]: boolean
}

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export const authReducer = (state: AuthTypes, action: AuthActions) => {
  switch (action.type) {
    case AuthActionTypes.SET_USER:
      return { ...state, user: action.payload }
    case AuthActionTypes.SET_USER_LOADING:
      return { ...state, userLoading: action.payload }
    case AuthActionTypes.SET_CLICKFARM_USER:
      return { ...state, isClickFarmUser: action.payload }
    default:
      return state
  }
}