
import Cookies from 'js-cookie';
import { MAIN_TOKEN_KEY } from '../../constants';


export const setCookie = (name: string, value: string, options?: Cookies.CookieAttributes) => {
    Cookies.set(name, value, options);
};

export const getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
};

export const removeCookie = (name: string) => {
    Cookies.remove(name);
};


export const set = (name: string, value: any) => {
    try {
        localStorage.setItem(name, JSON.stringify(value));

        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};


export const get = (name: string) => {
    try {
        return localStorage.getItem(name);
    } catch (err) {
        console.error(err);
        return false;
    }
};


export const remove = (name: string) => {
    try {
        localStorage.removeItem(name);

        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};


export const loggedIn = () => {
    const token = get(MAIN_TOKEN_KEY);
    return !!token;
};
