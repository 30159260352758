import { COLOR_THEME, THEME_MODE } from "constants/index";
import { set } from "web/utils/storage";

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
    }
    : {
        type: Key;
        payload: M[Key];
    }
};

export enum SettingActionTypes {
    TOGGLE_THEME = "TOGGLE_THEME",
    TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
    SET_COLOR_THEME = "SET_COLOR_THEME",
    TOGGLE_ALERT = "TOGGLE_ALERT",
    SET_LOGO = "SET_LOGO"
}


type AlertType = {
    isVisible: boolean;
    duration: number;
    type: string
    msg: string
}

export type SettingTypes = {
    isDarkMode: boolean
    isSidebarOpen: boolean
    colorTheme: string
    customAlert: AlertType
    appLogoUrl: string
}

type SettingPayload = {
    [SettingActionTypes.TOGGLE_THEME]: boolean
    [SettingActionTypes.TOGGLE_SIDEBAR]: boolean
    [SettingActionTypes.SET_COLOR_THEME]: string
    [SettingActionTypes.TOGGLE_ALERT]: AlertType
    [SettingActionTypes.SET_LOGO]: string
}

export type SettingActions = ActionMap<SettingPayload>[keyof ActionMap<SettingPayload>];


export const settingsReducer = (state: SettingTypes, action: SettingActions) => {

    switch (action.type) {
        case SettingActionTypes.TOGGLE_THEME:
            set(THEME_MODE, action.payload === true ? "dark" : "light")
            return { ...state, isDarkMode: action.payload }
        case SettingActionTypes.TOGGLE_SIDEBAR:
            return { ...state, isSidebarOpen: action.payload }
        case SettingActionTypes.SET_COLOR_THEME:
            set(COLOR_THEME, action.payload)
            return { ...state, colorTheme: action.payload }
        case SettingActionTypes.TOGGLE_ALERT:
            return { ...state, customAlert: action.payload }
        case SettingActionTypes.SET_LOGO:
            return { ...state, appLogoUrl: action.payload }
        default:
            return state
    }
}