// contexts/PWAInstallPromptContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface IPWAInstallPromptContext {
  showInstallPrompt: () => void;
  isInstallPromptSupported: boolean;
}

const PWAInstallPromptContext = createContext<IPWAInstallPromptContext>({
  showInstallPrompt: () => {},
  isInstallPromptSupported: false,
});

export const usePWAInstallPrompt = () => useContext(PWAInstallPromptContext);

interface Props {
  children: ReactNode;
}

export const PWAInstallPromptProvider: React.FC<Props> = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const showInstallPrompt = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();
      (deferredPrompt as any).userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("App installed");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <PWAInstallPromptContext.Provider
      value={{ showInstallPrompt, isInstallPromptSupported: !!deferredPrompt }}
    >
      {children}
    </PWAInstallPromptContext.Provider>
  );
};
