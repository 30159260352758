// web/components/MobilePrompt.tsx

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { isMobileDevice } from "web/utils/settings";
import { usePWAInstallPrompt } from "context/pwa/pwa";

const MobilePrompt: React.FC = () => {
  const { showInstallPrompt, isInstallPromptSupported } = usePWAInstallPrompt();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasUserClosedPrompt = Cookies.get("userClosedMobilePrompt");
    if (isMobileDevice() && isInstallPromptSupported && !hasUserClosedPrompt) {
      setIsVisible(true);
    }
  }, [isInstallPromptSupported]);

  const handleInstall = () => {
    showInstallPrompt();
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
    // Set a cookie to remember the user's choice for 30 days
    Cookies.set("userClosedMobilePrompt", "true", { expires: 30 });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white p-4 z-50">
      <img
        className="h-14 mb-[10vh]"
        src="https://socialapp.creativeguru.ai/content/img/company/creativeguru/logo-black-Socialguru-trans.png"
        alt="NewsGuru AI"
      />
      <h1 className="text-2xl font-bold mb-4">Get a Faster Experience</h1>
      <p className="mb-4 text-center">
        Install our app on your home screen for faster access and additional
        features.
      </p>
      <div className="flex flex-col space-y-4">
        <button
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
          onClick={handleInstall}
        >
          Install App
        </button>
        <button
          className="bg-transparent border border-gray-400 text-gray-500 font-semibold py-2 px-4 rounded"
          onClick={handleClose}
        >
          Continue on Web
        </button>
      </div>
    </div>
  );
};

export default MobilePrompt;
