import React, { createContext, useContext, useReducer } from "react";

import { get, remove } from "web/utils/storage";
import { MAIN_TOKEN_KEY, THEME_MODE } from "constants/index";
import {
  authReducer,
  AuthActions,
  AuthTypes,
  AuthActionTypes,
} from "./auth/reducer";
import {
  SettingActions,
  SettingActionTypes,
  settingsReducer,
  SettingTypes,
} from "./settings/reducer";

type InitialStateType = {
  auth: AuthTypes;
  settings: SettingTypes;
};

let theme = get(THEME_MODE) || "";
// let color = get(COLOR_THEME) || "";

if (theme) {
  theme = JSON.parse(theme);
}

// if (color) {
//   color = JSON.parse(color);
// }

const initialState: InitialStateType = {
  auth: {
    user: null,
    userLoading: true,
    isClickFarmUser: false,
  },
  settings: {
    isDarkMode: theme ? (theme === "dark" ? true : false) : true,
    isSidebarOpen: false,
    colorTheme: "brand",
    appLogoUrl: "",
    customAlert: {
      isVisible: false,
      duration: 5000,
      type: "",
      msg: "",
    },
  },
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<AuthActions | SettingActions | any>;
  logoutUser: () => void;
  createAlert: (msg: string, type: string) => void;
}>({
  state: initialState,
  dispatch: () => null,
  createAlert() {},
  logoutUser() {},
});

const mainReducer = (
  { auth, settings }: InitialStateType,
  action: AuthActions | SettingActions | any
) => ({
  auth: authReducer(auth, action),
  settings: settingsReducer(settings, action),
});

const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  const logoutUser = () => {
    dispatch({ type: AuthActionTypes.SET_USER, payload: null });
    dispatch({ type: AuthActionTypes.SET_CLICKFARM_USER, payload: false });
    remove(MAIN_TOKEN_KEY);
    window.location.href = "/login";
  };

  const createAlert = (msg: string, type: string) =>
    dispatch({
      type: SettingActionTypes.TOGGLE_ALERT,
      payload: {
        ...state.settings.customAlert,
        isVisible: true,
        msg,
        type,
      },
    });

  return (
    <AppContext.Provider value={{ state, dispatch, createAlert, logoutUser }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useAppState = () => useContext(AppContext);
