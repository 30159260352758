export const MAIN_TOKEN_KEY = "SOCIAL_GURU_TOKEN_KEY";
export const SESSION_KEY = "SESSION_TOKEN";
export const THEME_MODE = "THEME_MODE";
export const COLOR_THEME = "COLOR_THEME";
export const DASHBOARD_VIEW = "DASHBOARD_VIEW";
export const CREATIVE_ID_TOKEN = "CREATIVE_ID_TOKEN";


export const BLACK_LOGO = "https://socialapp.creativeguru.ai/content/img/company/creativeguru/logo-white-Socialguru-trans.png"
export const WHITE_LOGO = "https://socialapp.creativeguru.ai/content/img/company/creativeguru/logo-black-Socialguru-trans.png"

export const TRANSACTION_STATUS = {
  pending: 0,
  approved: 1,
  cancelled: 2
}

export const channelName: any = {
  'linkedin': "LinkedIn",
  'facebook': "Facebook",
  'twitter': "X (Twitter)",
  "": ""
}

//export const SOCIAL_CONNECTIONS = ["linkedin", 'facebook', 'twitter']
export const SOCIAL_CONNECTIONS = ["linkedin", 'twitter']